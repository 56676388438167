
const key = 'app/camera';
export const cameraConstants = {

GET_CAMERA_RECORDS: `${key}/GET_CAMERA_RECORDS`,
GET_CAMERA_RECORDS_ERROR: `${key}/GET_CAMERA_RECORDS_ERROR`,
GET_CAMERA_RECORDS_SUCCESS: `${key}/GET_CAMERA_RECORDS_SUCCESS`,

ADD_CAMERA: `${key}/ADD_CAMERA`,
ADD_CAMERA_ERROR: `${key}/ADD_CAMERA_ERROR`,
ADD_CAMERA_SUCCESS: `${key}/ADD_CAMERA_SUCCESS`,

EDIT_CAMERA: `${key}/EDIT_CAMERA`,
EDIT_CAMERA_ERROR: `${key}/EDIT_CAMERA_ERROR`,
EDIT_CAMERA_SUCCESS: `${key}/EDIT_CAMERA_SUCCESS`,

DELETE_CAMERA: `${key}/DELETE_CAMERA`,
DELETE_CAMERA_ERROR: `${key}/DELETE_CAMERA_ERROR`,
DELETE_CAMERA_SUCCESS: `${key}/DELETE_CAMERA_SUCCESS`,

VIEW_CAMERA: `${key}/VIEW_CAMERA`,
VIEW_CAMERA_ERROR: `${key}/VIEW_CAMERA_ERROR`,
VIEW_CAMERA_SUCCESS: `${key}/VIEW_CAMERA_SUCCESS`,

GET_CAMERA_ID_RECORDS: `${key}/GET_CAMERA_ID_RECORDS`,
GET_CAMERA_ID_RECORDS_ERROR: `${key}/GET_CAMERA_ID_RECORDS_ERROR`,
GET_CAMERA_ID_RECORDS_SUCCESS: `${key}/GET_CAMERA_ID_RECORDS_SUCCESS`,

GET_VENDOR: `${key}/GET_VENDOR`,
GET_VENDOR_ERROR: `${key}/GET_VENDOR_ERROR`,
GET_VENDOR_SUCCESS: `${key}/GET_VENDOR_SUCCESS`
};