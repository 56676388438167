import { cameraConstants } from "store/_constants/camera.constants";
import { cameraServices } from 'store/services/camera.services';
import { snackbarActions } from 'helpers/tools';


function getCamera(params) {
  
    return (dispatch) => {
      dispatch(request());
      cameraServices.getCamera(params).then(
        (res) => {

          const { response, error, message, total, page ,params} = res;
          if (error) {
            dispatch(failure(true, message));
            
          
          } else {
            dispatch(success(response, total, page,params));
           
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
      
        }
      );
    };
    function request() {
      return { type: cameraConstants.GET_CAMERA_RECORDS};
    }
    function success(records, total, page,) {
      return { type: cameraConstants.GET_CAMERA_RECORDS_SUCCESS, records, total, page };
    }
    function failure(error, message) {
      return { type: cameraConstants.GET_CAMERA_RECORDS_ERROR, error, message };
    }
  }

  function getCameraById(params) {
   
    return (dispatch) => {
      dispatch(request());
      cameraServices.getCameraById(params).then(
        (res) => {
          const { response, error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
           
          } else {
            dispatch(success(response));
          
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        
        }
      );
    };
    function request() {
      return { type: cameraConstants.GET_CAMERA_ID_RECORDS};
    }
    function success(records) {
      return { type: cameraConstants.GET_CAMERA_ID_RECORDS_SUCCESS, records };
    }
    function failure(error, message) {
      return { type: cameraConstants.GET_CAMERA_ID_RECORDS_ERROR, error, message };
    }
  }

  function viewCamera(params) {
   
    return (dispatch) => {
      dispatch(request());
      cameraServices.viewCamera(params).then(
        (res) => {
          const { response, error, message, total, page } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
        
          } else {
            dispatch(success(response, total, page));
           
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
       
        }
      );
    };
    function request() {
      return { type: cameraConstants.VIEW_CAMERA};
    }
    function success(records, total, page) {
      return { type: cameraConstants.VIEW_CAMERA_SUCCESS, records, total, page };
    }
    function failure(error, message) {
      return { type: cameraConstants.VIEW_CAMERA_ERROR, error, message };
    }
  }

  function addCamera(payload, navigate) {
    return (dispatch) => {
      dispatch(request( ));
      cameraServices.addCamera(payload).then(
        (res) => {
          const { response, error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
          } else {
            dispatch(success(response));
            navigate('/preview-camera')
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        }
      );
    };
    function request() { 
      return { type: cameraConstants.ADD_CAMERA };
    }
    function success(cameraDetails) {
      return { type: cameraConstants.ADD_CAMERA_SUCCESS, cameraDetails };
    }
    function failure(error, message) {
      return { type: cameraConstants.ADD_CAMERA_ERROR, error, message };
    }
  }

  function updateCamera(payload, id, navigate) {
    return (dispatch) => {
      dispatch(request());
      cameraServices.updateCamera(payload, { id }).then(
        (res) => {
          const { response, error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
          } else {
            dispatch(success(response));
            navigate('/camera');
          }
        },
        (error) => {
          dispatch(failure(true, error.toString()));
        }
      );
    };
  
    function request() {
      return { type: cameraConstants.EDIT_CAMERA };
    }
    function success(response) {
      return { type: cameraConstants.EDIT_CAMERA_SUCCESS, response };
    }
    function failure(error, message) {
      return { type: cameraConstants.EDIT_CAMERA_ERROR, error, message };
    }
  }

  function deleteCamera(id, page, rowsPerPage) {

    return (dispatch) => {
      dispatch(request());
      cameraServices.deleteCamera(id).then(
        (res) => {
          const { error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));     
          } else {
            dispatch(success(res));
            const params = {
              page,
              per_page: rowsPerPage,
            };
            dispatch(getCamera(params));
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
      
        }
      );
    };
    function request() {
      return { type: cameraConstants.DELETE_CAMERA};
    }
    function success(res) {
      return { type: cameraConstants.DELETE_CAMERA_SUCCESS,res};
    }
    function failure(error, message) {
      return { type: cameraConstants.DELETE_CAMERA_ERROR, error, message };
    }
  }

  function getVendor(params) {
    return (dispatch) => {
      dispatch(request());
      cameraServices.getVendor(params).then(
        (res) => {
          const { response, error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
            // dispatch(loaderActions.end());
          } else {
            dispatch(success(response));
            // dispatch(loaderActions.end());
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        //   dispatch(loaderActions.end());
        }
      );
    };
    function request() {  
      return { type: cameraConstants.GET_VENDOR};
    }
    function success(records) {
      return { type: cameraConstants.GET_VENDOR_SUCCESS, records };
    }
    function failure(error, message) {
      return { type: cameraConstants.GET_VENDOR_ERROR, error, message };
    }
  }

export const cameraActions = {
  getCamera,
  addCamera,
  viewCamera,
  deleteCamera,
  getCameraById,
  updateCamera,
  getVendor
}