import { useRoutes } from 'react-router-dom';

// project imports
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTES RENDER ||============================== //

const ThemeRoutes = () => {
  return useRoutes([LoginRoutes, MainRoutes]);
};

export default ThemeRoutes;
