// import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';
/**
 *
 * @param {function} columns
 * @param {object} record
 * @param {object} locationState
 */


export const getDefaultParams = (payload, queryString, isAuthRequired = true) => {
  const params = { ...payload, ...queryString?.queryStringParameters };
 
  const config = {
    params,
  };
 
  if (isAuthRequired) {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }
 
  return config;
};


export const dateFormat = (date) =>{
  if (date === '-') {
    return '-';
  }
  const convertedValue = dayjs(date).format('D MMM, YYYY');
  return convertedValue;
}

export const getDefaultParamswithoutlimitkey = (payload, queryString) => {
  return Object.assign({}, payload, queryString);
};

export function snackbarActions(error, message, userInteraction = false) {
  return (dispatch) => {
    if (error) {
      if (userInteraction) {
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'userInteraction',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true,
            error:true

          })
        );
      } else {
        dispatch(
          openSnackbar({
           
            open: true,
            message: message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true,
            error:true
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          severity: 'success',
          close: true,
          error:false
        })
      );
    }
  };
}

export function snackbarClose() {
  return (dispatch) => {
    dispatch(closeSnackbar());
  };
}

// export function slientSnackbar(message) {
//   return (dispatch) => {
//     dispatch(openSilentSnackbar({ message }));
//   };
// }

export function isEmpty(object = {}) {
  if (object && Object.keys(object).length) return false;
  return true;
}

export const buildQuery = (params) => {
  const { pageSize, page, delivery_date, queryParams, imageId, category_type } = params;
  let { filters, columnFilters } = params;
  let conditional_operator = 'or';
  if (columnFilters && !isEmpty(columnFilters)) {
    filters = columnFilters;
    conditional_operator = 'and';
  }
  const queryObject = { pageSize, page, delivery_date, imageId, category_type };
  if (filters && !isEmpty(filters)) {
    if (typeof filters == 'string') {
      queryObject.filters = filters;
    } else {
      let arr = [];
      let keys = Object.keys(filters);
      keys.forEach((key) => {
        if (filters[key]) {
          arr.push(`${key}=${filters[key]}`);
        }
      });
      if (arr.length) {
        queryObject.filters = arr.join(',');
        queryObject.conditional_operator = conditional_operator;
      }
    }
  }
  if (queryParams && !isEmpty(queryParams)) {
    let keys = Object.keys(queryParams);
    keys.forEach((key) => {
      if (queryParams[key]) {
        queryObject[key] = queryParams[key];
      }
    });
  }
  return JSON.parse(JSON.stringify(queryObject));
};

export const CREATE_SUCCESS = 'createSuccess';

export function isSpecialChar(value) {
  return /^(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?]).+$/.test(value);
}

export function minLength(value) {
  return value.length > 7;
}

export function isUppercaseChar(value) {
  return new RegExp('^(?=.*[A-Z]).+$').test(value);
}
