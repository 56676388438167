import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase,    Tooltip,   } from '@mui/material';

// project-imports





import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';


import { Logout } from 'iconsax-react';



// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  



  return (
    <Box sx={{lexShrink: 0, 
  ml: 0.75, 
  bgcolor: " #ccebff", 
  display: 'inline-flex',
  borderRadius: 1,
  p: 0.25,  }}>
      <ButtonBase
        sx={{
         
          p: 0.25,
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
       <Tooltip title="Logout">
                          <IconButton size="large" color="#001a66" sx={{ p: 1 }} onClick={handleLogout}>
                            <Logout variant="Bulk" />
                          </IconButton>
                        </Tooltip>
                       
      </ButtonBase>
      
     
    </Box>
  );
};

export default ProfilePage;
