// material-ui
// import { useTheme } from '@mui/material/styles';
import SvgIcons from "components/SvgElement";
import logoIcon from 'assets/icons/logoIcon.svg'

// import logoIcon from 'assets/icons/logoIcon.svg'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = ({logo, width, height}) => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
   
    <SvgIcons icon={logo || logoIcon}  width={width ||130} height={height || 130}/>

  );
};

export default LogoIcon;
