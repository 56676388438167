import API from 'axios-config'
import { getDefaultParams,} from 'helpers/tools';
 
function getCamera(params) {

  return API.get('/camera', getDefaultParams({}, {queryStringParameters: params }))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function getCameraById(params) {

  return API.get('/camera/get-camera-edit', getDefaultParams({}, {queryStringParameters: params }))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function getVendor(params) {
  
  return API.get('/camera/dropdown', getDefaultParams({}, {queryStringParameters: params }))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function deleteCamera(params) {
  return API.delete('/camera', getDefaultParams({}, {queryStringParameters: params }))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function viewCamera(params) {
  return API.get('/camera/view-camera', getDefaultParams({}, {queryStringParameters: params }))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function addCamera(payload) {
  const config = getDefaultParams({}, null);
  return API.post('/camera', payload, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}

function updateCamera(payload,params) {
  const config = getDefaultParams({}, {queryStringParameters: params});
  return API.put('/camera', payload, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}
export const cameraServices = {
    getCamera,
    addCamera,
    viewCamera,
    deleteCamera,
    getCameraById,
    updateCamera,
    getVendor
};
