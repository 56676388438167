// import { useEffect, useState } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

// import Loader from 'components/Loader';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
// import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// import { dispatch } from 'store';
// import { fetchDashboard } from 'store/reducers/menu';

// auth-provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { TableContextProvider  } from 'contexts/TableContext'; 


// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  // const [loading, setLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   dispatch(fetchDashboard()).then(() => {
  //     setLoading(true);
  //   });
  // }, []);

  // if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                <TableContextProvider>
                  <Routes />
                </TableContextProvider>
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
