import { Amplify, Auth } from 'aws-amplify';
// import API from 'axios-config'
import { cognitoPool } from '../../aws-config/index';

Amplify.configure({
  ...cognitoPool.auth
});

function login(username, password) {
  return Auth.signIn(username, password)
  .then((response) => response)
  .catch((error) => Promise.reject(error))
}

function logout(navigate) {
  Auth.signOut()
    .then(() => {
      localStorage.clear();
      if (navigate) {
        navigate('/login');
      }
    })
    .catch(error => {
      console.error('Error signing out:', error);
    });
}

// function register(payload) {
//   return API.post('no-auth', '/create-user', { body: payload })
//     .then((response) => response)
//     .catch((error) => Promise.reject(error));
// }

// function register(payload) {
//   return API.post('/create-user', {body: payload })
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       console.error('API Error:', error);
//       return Promise.reject(error);
//     });
// }

function register(payload) {
  return fetch('https://api.dev.ppedetection.com/create-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(response => {
    if (!response.ok) {
      return response.json().then(error => Promise.reject(error));
    }
    return response.json();
  })
  .catch(error => Promise.reject(error));
}

function otpVerification(record) {
  return fetch('https://api.dev.ppedetection.com/create-user/verify-otp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(record),
  })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.json();
    })
    .catch((error) => Promise.reject(error));
}

// function otpVerification(payload) {
//   return API.post('/create-user/verify-otp', {body: payload })
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       console.error('API Error:', error);
//       return Promise.reject(error);
//     });
// }

// function otpVerification(payload) {
//   return API.post('no-auth', '/create-user/verify-otp', { body: payload })
//     .then((response) => response)
//     .catch((error) => Promise.reject(error));
// }

function forgotPassword(email) {
  return Auth.forgotPassword(email)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function forgotPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const sessionService = {
  login,
  logout,
  register,
  forgotPassword,
  forgotPasswordSubmit,
  otpVerification
};
