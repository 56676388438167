// project-imports
import applications from './applications';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [applications]
};

export default menuItems;
