import { sessionService } from '../services';
import { sessionConstants } from '../_constants';
import { snackbarActions, snackbarClose } from 'helpers/tools';

function login(email, password, navigate) {
    return (dispatch) => {
      dispatch(request({ email }));
      sessionService.login(email, password).then(
        (response) => {
            dispatch(success(response));
            setSession(response);
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
            dispatch(snackbarActions(true, error.toString()));
          setTimeout(() => {
            dispatch(snackbarClose());
          }, 3000);
        }
      );
    };
  
    function request(email) {
      return { type: sessionConstants.LOG_IN, email };
    }
    function success(user) {
      return { type: sessionConstants.LOG_IN_SUCCESS, user };
    }
    function failure(error, message) {
      return { type: sessionConstants.LOG_IN_ERROR, error, message };
    }
  }

  function logout() {
    return (dispatch) => {
      sessionService.logout();
      setSession(null);
      dispatch(sessionLogout());
    };
    function sessionLogout() {
      return { type: sessionConstants.LOG_OUT };
    }
  }

  function register(payload, navigate) {
    return (dispatch) => {
      dispatch(request());
      sessionService.register(payload).then(
        (res) => {
          const { message, error } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
          } else {
            dispatch(success(res, message));
            dispatch(snackbarActions(false, message));
            if (navigate) {
              navigate('/code-verification', { state: { email: payload.email } });
            }
          }
          setTimeout(() => {
            dispatch(snackbarClose());
          }, 3000);
        },
        (error) => {
          console.error('Registration error:', error);
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
          dispatch(snackbarActions(true, error));
        }
      );
    };
  
    function request() {
      return { type: sessionConstants.REGISTER };
    }
    function success(user, message) {
      return { type: sessionConstants.REGISTER_SUCCESS, user, message };
    }
    function failure(error, message) {
      return { type: sessionConstants.REGISTER_ERROR, error, message };
    }
  }

  function loginSuccess(serviceToken) {
    return (dispatch) => {
      dispatch(success(serviceToken));
    };
    function success(serviceToken) {
      return { type: sessionConstants.LOG_IN_SUCCESS_EXIST, serviceToken };
    }
  }
  
  const setSession = (response = null, email) => {
    if (response) {
      const serviceToken = response.signInUserSession.getIdToken().getJwtToken();
      localStorage.setItem('token', serviceToken);
      localStorage.setItem('user', response.username);
      if(response?.attributes){
        localStorage.setItem('name', response?.attributes?.name);
      }else{
        localStorage.setItem('name', response?.challengeParam?.userAttributes?.name);
      }
    } else {
      localStorage.clear()
    }
  };

  function forgotPassword(email, navigate) {
    return (dispatch) => {
      dispatch(request());
      // dispatch(loaderActions.start());
      sessionService.forgotPassword(email).then(
        (res) => {
          const { error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
          } else {
            dispatch(success(email));
            if (navigate) {
              navigate('/reset-password', { state: { email } });
            }
          }
          setTimeout(() => {
            dispatch(snackbarClose());
          }, 3000);
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
            dispatch(failure(true, error.message));
            dispatch(snackbarActions(true, error.message));
          }
          dispatch(failure(true, error.toString()));
          dispatch(snackbarActions(true, error.toString()));
          setTimeout(() => {
            dispatch(snackbarClose());
          }, 2000);
        }
      );
    };
  
    function request() {
      return { type: sessionConstants.FORGOT_PASSWORD };
    }
    function success(email) {
      return { type: sessionConstants.FORGOT_PASSWORD_SUCCESS, email };
    }
    function failure(error, message) {
      return { type: sessionConstants.FORGOT_PASSWORD_ERROR, error, message };
    }
  }
  
  function forgotPasswordSubmit(username, code, password, navigate) {
    return (dispatch) => {
      dispatch(request());
      sessionService.forgotPasswordSubmit(username.email, code, password).then(
        (res) => {
          dispatch(success(res));
          dispatch(snackbarActions(false, 'Successfully resetted'));
          setTimeout(() => {
            dispatch(snackbarClose());
          }, 2000);
          if (navigate) {
            navigate('/login');
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
          dispatch(snackbarActions(error, error.toString()));
        }
      );
    };
  
    function request() {
      return { type: sessionConstants.VERIFY_PASSWORD_CODE };
    }
    function success(message) {
      return { type: sessionConstants.VERIFY_PASSWORD_CODE_SUCCESS, message };
    }
    function failure(error, message) {
      return { type: sessionConstants.VERIFY_PASSWORD_CODE_ERROR, error, message };
    }
  }

  function otpVerification(record, navigate) {
    return (dispatch) => {
      dispatch(request());
      sessionService.otpVerification(record).then(
        (res) => {
          const { error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
          } else {
            dispatch(success(false, message));
            dispatch(snackbarActions(false, message));
            navigate('/login')
          }
        },
        (error) => {
          if (error?.response.status >= 400 && error?.response.status <= 500) {
            error = error?.response?.data?.message;
          }
          dispatch(failure(true, error.toString()));
          dispatch(snackbarActions(true, error));
        }
      );
    };
    function request() {
      return { type: sessionConstants.OTP_VERIFICATION };
    }
    function success(error, message) {
      return { type: sessionConstants.OTP_VERIFICATION_SUCCESS, error, message };
    }
    function failure(error, message) {
      return { type: sessionConstants.OTP_VERIFICATION_ERROR, error, message };
    }
  }

  export const userActions = {
    login,
    logout,
    register,
    loginSuccess,
    forgotPassword,
    forgotPasswordSubmit,
    otpVerification
  };