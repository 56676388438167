import { dashboardConstants } from "store/_constants/dashboard.constants";
import { dashboardServices } from 'store/services/dashboard.services';
import { snackbarActions } from 'helpers/tools';

function getDashboard(params) {
   
    return (dispatch) => {
      dispatch(request());
      dashboardServices.getDashboard(params).then(
        (res) => {
          const { response, error, message } = res;
          if (error) {
            dispatch(failure(true, message));
            dispatch(snackbarActions(true, message));
           
          } else {
            dispatch(success(response));
          
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        
        }
      );
    };
    function request() {
      return { type: dashboardConstants.GET_DASHBOARD};
    }
    function success(records) {
      return { type: dashboardConstants.GET_DASHBOARD_SUCCESS, records };
    }
    function failure(error, message) {
      return { type: dashboardConstants.GET_DASHBOARD_ERROR, error, message };
    }
  }

  export const dashboardActions = {
    getDashboard
  }
