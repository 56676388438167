import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import * as store from 'store';

// render - dashboar
const Dashboard = Loadable(lazy(() => import('pages/apps/dashboard/dashboard')));
const Camera = Loadable(lazy(() => import('pages/apps/camera/camera')));
const AddCamera = Loadable(lazy(() => import('pages/apps/camera/addCamera')));
const PreviewCamera = Loadable(lazy(() => import('pages/apps/camera/previewCamera')));
const ViewCamera = Loadable(lazy(() => import('pages/apps/camera/viewCamera')));
const EditCamera = Loadable(lazy(() => import('pages/apps/camera/editCamera')));

// table routing
// const MuiTableBasic = Loadable(lazy(() => import('pages/tables/mui-table/basic')));


// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard {...store} />
        },
        {
          path: 'camera',
          element: <Camera {...store}/>
        },
        {
          path: 'add-camera',
          element: <AddCamera {...store}/>
        },
        {
          path: 'preview-camera',
          element: <PreviewCamera {...store}/>
        },
        {
          path: 'view-camera/:id',
          element: <ViewCamera {...store}/>
        },
        {
          path: 'edit-camera/:id',
          element: <EditCamera {...store}/>
        },


  ]
  }
]
}

export default MainRoutes;
