// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
// import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
// import productReducer from './product';
import cartReducer from './cart';
// import kanban from './kanban';
// import invoice from './invoice';
import sessionReducer from './session.reducers';
import cameraReducer from './camera.reducers';
import dashboardReducer from './dashboard.reducers';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  sessionReducer,
  cameraReducer: persistReducer(
    {
      key: 'Add',
      storage,
      keyPrefix: 'camera-data-'
    },
    cameraReducer
  ),
  
  dashboardReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),


});

export default reducers;
