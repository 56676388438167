export const sessionConstants = {
  
    LOG_IN: 'app/session/LOG_IN',
    LOG_IN_SUCCESS: 'app/session/LOG_IN_SUCCESS',
    LOG_IN_ERROR: 'app/session/LOG_IN_ERROR',

    LOG_OUT: 'app/session/LOG_OUT',
    LOG_OUT_SUCCESS: 'app/session/LOG_OUT_SUCCESS',
    LOG_OUT_ERROR: 'app/session/LOG_OUT_ERROR',
    LOG_IN_SUCCESS_EXIST: 'app/session/LOG_IN_SUCCESS_EXIST',

    FORGOT_PASSWORD: 'app/session/FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS: 'app/session/FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR: 'app/session/FORGOT_PASSWORD_ERROR',

    REGISTER: 'app/session/REGISTER',
    REGISTER_SUCCESS: 'app/session/REGISTER_SUCCESS',
    REGISTER_ERROR: 'app/session/REGISTER_ERROR',
  
    VERIFY_PASSWORD_CODE: 'app/session/VERIFY_PASSWORD_CODE',
    VERIFY_PASSWORD_CODE_SUCCESS: 'app/session/VERIFY_PASSWORD_CODE_SUCCESS',
    VERIFY_PASSWORD_CODE_ERROR: 'app/session/VERIFY_PASSWORD_CODE_ERROR',
    RESET_LOGIN: 'app/session/RESET_LOGIN',
  
    OTP_VERIFICATION: 'app/session/OTP_VERIFICATION',
    OTP_VERIFICATION_ERROR: 'app/session/OTP_VERIFICATION_ERROR',
    OTP_VERIFICATION_SUCCESS: 'app/session/OTP_VERIFICATION_SUCCESS',
  
    OTP_RESEND: 'app/session/OTP_RESEND',
    OTP_RESEND_SUCCESS: 'app/session/OTP_RESEND_SUCCESS',
    OTP_RESEND_ERROR: 'app/session/OTP_RESEND_ERROR',
  
    CREATE_USER: 'app/session/CREATE_USER',
    CREATE_USER_SUCCESS: 'app/session/CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: 'app/session/CREATE_USER_ERROR',
  
};