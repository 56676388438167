/*
 *
 * Session reducer
 *
 */
import produce from 'immer';
import { cameraConstants } from '../_constants';

const initialState = {
  cameraDetails:{},
  records: [],
  cameraByIdRecords: {},
  viewCameraRecords: [],
  vendorRecords: [],
  loading: false,
  buttonLoading: false,
  success: false,
  error: false,
  message: null,
  total: null,
  viewCameraRecordsTotal: null,
  page: 0,
  pageSize: 5,
};

/**
 * @param {object} state
 * @param {object} action
 */
const cameraReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records, cameraDetails, total, page } = action;
   
    switch (action.type) {
      case cameraConstants.GET_CAMERA_RECORDS:
        draft.loading = true;
        break;
      case cameraConstants.GET_CAMERA_RECORDS_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        draft.total = total;
        draft.page = page;
        break;
      case cameraConstants.GET_CAMERA_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.GET_CAMERA_ID_RECORDS:
        draft.loading = true;
        break;
      case cameraConstants.GET_CAMERA_ID_RECORDS_SUCCESS:
        draft.cameraByIdRecords = records;
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        draft.total = total;
        draft.page = page;
        break;
      case cameraConstants.GET_CAMERA_ID_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.VIEW_CAMERA:
        draft.loading = true;
        break;
      case cameraConstants.VIEW_CAMERA_SUCCESS:
        draft.viewCameraRecords = records;
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        draft.viewCameraRecordsTotal = total;
        draft.page = page;
        break;
      case cameraConstants.VIEW_CAMERA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.ADD_CAMERA:
        draft.buttonLoading = true;
        draft.error = false;
        draft.message = null;
    
        break;
      case cameraConstants.ADD_CAMERA_SUCCESS:
        draft.buttonLoading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        draft.cameraDetails=cameraDetails;
        break;
      case cameraConstants.ADD_CAMERA_ERROR:
        draft.buttonLoading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.EDIT_CAMERA:
        draft.buttonLoading= true;
        draft.error = false;
        draft.message = null;
        break;
      case cameraConstants.EDIT_CAMERA_SUCCESS:
        draft.buttonLoading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case cameraConstants.EDIT_CAMERA_ERROR:
        draft.buttonLoading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.DELETE_CAMERA:
        draft.loading = true;
        draft.error = false;
        draft.message = null;
        break;
      case cameraConstants.DELETE_CAMERA_SUCCESS:
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case cameraConstants.DELETE_CAMERA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
      case cameraConstants.GET_VENDOR:
        draft.loading = true;
        break;
      case cameraConstants.GET_VENDOR_SUCCESS:
        draft.vendorRecords = records;
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case cameraConstants.GET_VENDOR_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
    }
  });
export default cameraReducer;
