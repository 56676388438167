import API from 'axios-config'
import { getDefaultParams } from 'helpers/tools';

function getDashboard(params) {
    // const params = {page: 0, per_page: 10};
    return API.get('/dashboard', getDefaultParams({}, {queryStringParameters: params }))
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('API Error:', error);
        return Promise.reject(error);
      });
  }

  export const dashboardServices = {
    getDashboard
};