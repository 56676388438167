import produce from 'immer';
import { dashboardConstants } from '../_constants';

const initialState = {
    records: [],
    loading: false,
    success: false,
    error: false,
    message: null,
    total: null,
    page: 0,
    pageSize: 5,
  };

  /**
 * @param {object} state
 * @param {object} action
 */
const dashboardReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { error, message, records, total, page } = action;
        switch (action.type) {
            case dashboardConstants.GET_DASHBOARD:
                draft.loading = true;
                break;
            case dashboardConstants.GET_DASHBOARD_SUCCESS:
                draft.records = records;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.message = message;
                draft.total = total;
                draft.page = page;
                break;
            case dashboardConstants.GET_DASHBOARD_ERROR:
                draft.loading = false;
                draft.error = error;
                draft.message = message;
                break;
            default:
                break;
        }
    });
export default dashboardReducer;