import axios from 'axios';
 
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.dev.ppedetection.com',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});
 
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            console.error('API Error:', error.response.data);
        } else {
            console.error('Network Error');
        }
        return Promise.reject(error.response || error.message);
    }
);
 
const AxiosApiService = {
    get: (url, config = {}) => {
        return axiosInstance.get(url, config);
    },
 
    post: (url, data, config = {}) => {
        return axiosInstance.post(url, data, config);
    },
 
    put: (url, data, config = {}) => {
        return axiosInstance.put(url, data, config);
    },
 
    delete: (url, config = {}) => {
        return axiosInstance.delete(url, config);
    },
};
 
export default AxiosApiService;