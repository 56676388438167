
// assets
import { Home3, Camera } from 'iconsax-react';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  // title: <FormattedMessage id="applications" />,
  // icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: Home3,
      url: '/dashboard'
    },
    {
      id: 'camera',
      title: 'Camera',
      type: 'item',
      icon: Camera,
      url: '/camera'
    }
  ]
};

export default applications;
