/*
 *
 * Session reducer
 *
 */
import produce from 'immer';
import { sessionConstants } from '../_constants';

const initialState = {
  loading: false,
  success: false,
  error: false,
  isInitialized: true,
  user: null,
  email: null,
  isLoggedIn: false,
  newPassword: false,
  metaData: {},
  message: null,
  rolesPermissions: [],
  iconUrl: '',
  isPermissions: false,
  isRegistering: false,
  completePwd: false,
  forgotPwd: false,
  loginCompleted: false,
  isCompanyorEmailExist: false,
  compressToken: null,
  scenarioData: {}
};

/**
 * @param {object} state
 * @param {object} action
 */
const sessionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, user, email, scenarioData} = action;
    switch (action.type) {
      case sessionConstants.LOG_IN:
        draft.loading = true;
        draft.email = email;
        draft.isInitialized = true;
        draft.isPermissions = false;
        draft.forgotPwd = false;
        break;
      case sessionConstants.LOG_IN_SUCCESS_EXIST:
      case sessionConstants.LOG_IN_SUCCESS:
        draft.isInitialized = true;
        draft.isLoggedIn = true;
        draft.user = user;
        draft.loginCompleted = true;
        break;
      case sessionConstants.LOG_IN_ERROR:
        draft.isLoggedIn = false;
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        break;
      case sessionConstants.LOG_OUT_ERROR:
      case sessionConstants.LOG_OUT:
        draft.isLoggedIn = false;
        draft.user = null;
        draft.loading = false;
        draft.isPermissions = false;
        draft.completePwd = false;
        draft.isRegistering = false;
        draft.forgotPwd = false;
        break;
      case sessionConstants.REGISTER:
        draft.loading = true;
        draft.isRegistering = true;
        draft.isCompanyorEmailExist = false;
        break;
      case sessionConstants.REGISTER_SUCCESS:
        draft.isRegistering = false;
        draft.loading = false;
        break;
      case sessionConstants.REGISTER_ERROR:
        draft.loading = false;
        draft.isRegistering = false;
        if (message === 'The user already exists.') {
          draft.isCompanyorEmailExist = true;
        }
        draft.error = error;
        draft.message = message;
        break;
      case sessionConstants.FORGOT_PASSWORD:
        draft.loading = true;
        break;
      case sessionConstants.FORGOT_PASSWORD_ERROR:
        draft.forgotError = action.error;
        draft.loading = false;
        break;
      case sessionConstants.FORGOT_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.success = false;
        draft.forgotPwd = true;
        draft.email = action.email;
        break;
      case sessionConstants.GET_SCENARIOS:
        draft.loading = true;
        draft.success = false;
        break;
      case sessionConstants.GET_SCENARIOS_SUCCESS:
        draft.scenarioData = scenarioData;
        break;
      case sessionConstants.VERIFY_PASSWORD_CODE:
        draft.loading = true;
        break;
      case sessionConstants.VERIFY_PASSWORD_CODE_ERROR:
        draft.loading = false;
        draft.error = error;
        break;
      case sessionConstants.VERIFY_PASSWORD_CODE_SUCCESS:
        draft.loading = false;
        break;
      case sessionConstants.SOCIAL_LOG_IN_SUCCESS:
        draft.loading = false;
        break;  
      case sessionConstants.NEW_PASSWORD_REQUIRED:
        draft.newPassword = true;
        draft.completePwd = false;
        draft.loading = false;
        draft.user = user;
        break;
      case sessionConstants.COMPLETE_NEW_PASSWORD_INIT:
        draft.loading = true;
        draft.newPassword = false;
        break;
        case sessionConstants.OTP_VERIFICATION:
        draft.loading = true;
        break;
      case sessionConstants.OTP_VERIFICATION_ERROR:
        draft.loading = false;
        draft.error = error;
        break;
      case sessionConstants.OTP_VERIFICATION_SUCCESS:
        draft.loading = false;
        break; 

      default:
        break;
    }
  });
export default sessionReducer;
