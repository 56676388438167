import React, { createContext, useEffect } from 'react';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import Loader from 'components/Loader';
import { userActions } from 'store/actions';
import { useDispatch, useSelector } from 'store';
import config from 'aws-config';

const awsConfig = {
  ...config
};

export const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.Auth.userPoolId,
  ClientId: awsConfig.Auth.userPoolWebClientId
});

const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  const state = useSelector((state) => state.sessionReducer);
  const { isInitialized } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('token');
        if(state.user){
          if (serviceToken) {
            dispatch(userActions.loginSuccess(serviceToken));
          } else {
            dispatch(userActions.logout());
          }
        }
        if(!state.user){
          if (serviceToken) {
            dispatch(userActions.loginSuccess(serviceToken));
          }
        }
      } catch (err) {
        console.error(err);
        dispatch(userActions.logout());
      }
    };
    init();
  }, []);

  const login = async (email, password, navigate) => {
    dispatch(userActions.login(email, password, navigate));
  };


  const logout = () => {
    dispatch(userActions.logout());
    localStorage.removeItem('DefaultRoute');
  };

  const forgotPassword = async (email) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    user.forgotPassword({
      onSuccess: function () { },
      onFailure: function () { }
    });
  };

  const resetPassword = async (verificationCode) => {
    const email = localStorage.getItem('email');
    const user = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, '', {
        onSuccess: function (data) {
          localStorage.removeItem('email');
          resolve(data);
        },
        onFailure: function (error) {
          reject(error.message);
        }
      });
    });
  };

  const updateProfile = () => { };

  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider value={{ ...state, login, logout, forgotPassword, resetPassword, updateProfile }}>
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
