import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project-imports
// import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to, logo, width, height }) => (
  <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    <LogoIcon logo={logo} width={width} height={height}/> 
  </ButtonBase>
);

export default LogoSection;
